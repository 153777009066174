<template>
  <div class="container">
    <div class="mainfam" v-if="showedit === 'main'">
      <dig title="添加客户" :istext="false" :show.sync="isDetail">
        <div><img :src="createUser" alt="" /></div>
      </dig>
      <div class="familytitle" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).centerbg+');background-repeat: no-repeat'">
        <div class="famtitleleft">
          <p class="famtitletop">家庭成员列表</p>
          <p>获得更全面的保单分析</p>
        </div>
        <div class="famtitleright" v-if="personkey === '0'">
          <!--<img src="@/assets/abd/image/addfam.png"-->
            <!--alt=""-->
            <!--@click="addfamember"-->
          <!--/>-->
          <img :src="getStorage('ossurl','')+getStorage('imgs',{}).addcy" alt="" @click="addfamember">
        </div>
      </div>
      <!--{{getStorage('ossurl','')}}-->
      <!--{{getStorage('imgs',{}).addcy}}-->
      <div class="policyContainer">
        <div class="bodylist" v-if="userlist.length">
          <div class="list" v-for="(i, index) in userlist" :key="index">
            <div class="listleft" @click="lookfampolicy(i)">
              <img v-if="i.headimg" :src="i.headimg">
              <img v-if="!i.headimg" src="@/assets/abd/image/SAAS-76.png" alt="head" />
            </div>
            <div class="listright" @click.stop="lookfampolicy(i)">
              <div @click="lookfampolicy(i)">
                <p class="name">
                  {{ i.name }}
                </p>
              </div>
              <p class="editbtn" :style="'color:'+getStorage('theme','')+';'+'border:1px solid '+getStorage('theme','')" @click.stop="edit(i)" v-if="personkey === '0'">编辑</p>
            </div>
          </div>
        </div>
        <md-result-page
          :img-url="require('@/assets/abd/image/null.png')"
          v-else
          subtext="要不然刷新试试？"
        />
      </div>
    </div>
    <div class="addfamember" v-if="showedit === 'dialog'">
      <div class="addfamily-title">
        <img
          src="@/assets/abd/image/backtofamily.png"
          alt=""
          style="width:5vw;height:4vw"
          @click="tomainpage"
        />
        <span>{{ editflag }}</span>
      </div>
      <div class="addfamily-body">
        <md-field>
          <md-input-item
            title="姓名"
            class="require"
            placeholder="请填写真实姓名"
            v-model="addform.name"
            align="right"
          ></md-input-item>
          <md-field-item title="关系" align="right">
            <md-radio-group v-model="addform.relation">
              <md-radio-box name="00">户主</md-radio-box>
              <md-radio-box name="01">父母</md-radio-box>
              <md-radio-box name="02">配偶</md-radio-box>
              <md-radio-box name="03">子女</md-radio-box>
              <md-radio-box name="04">兄弟</md-radio-box>
              <md-radio-box name="05">兄妹</md-radio-box>
            </md-radio-group>
          </md-field-item>
          <md-field-item title="性别" align="right">
            <md-radio name="2" v-model="addform.sex" label="男" inline />
            <md-radio name="1" v-model="addform.sex" label="女" inline />
          </md-field-item>
          <md-field-item
            title="出生日期："
            :solid="false"
            arrow="arrow-right"
            align="right"
            :content="addform.birthdate"
            @click.native="isDatePickerShow = true"
          >
          </md-field-item>
          <md-date-picker
            ref="datePicker2"
            v-model="isDatePickerShow"
            type="custom"
            title="选择出生日期"
            :min-date="minDate"
            large-radius
            :default-date="new Date('2000/1/1')"
            :custom-types="['yyyy', 'MM', 'dd']"
            @change="onDatePickerChange"
            @confirm="onDatePickerConfirm"
          ></md-date-picker>
          <md-field-item
            title="证件类型："
            :content="cardtype"
            @click="showcardSelector"
            arrow
            :solid="false"
          >
          </md-field-item>

          <md-selector
            v-model="cardSelectorShow"
            :data="cardselectdata"
            title="证件类型"
            default-value="1"
            large-radius
            @choose="oncardtypeChoose"
          ></md-selector>
          <md-input-item
            title="证件号码："
            placeholder="证件号码"
            :solid="false"
            name="cardnum"
            @blur="inputcardnum"
            v-model="addform.cardno"
            align="right"
          ></md-input-item>
          <md-field-item
            v-if="editflag === '编辑成员'"
            title="家庭成员微信："
            :content="wxnum"
            @click="wxcardShow = true"
            arrow
            :solid="false"
          >
          </md-field-item>

          <md-selector
            v-model="wxcardShow"
            :data="wxcarddata"
            title="家庭成员微信"
            default-value="1"
            large-radius
            min-height="220px"
            max-height="400px"
            @choose="onwxcardChoose"
          >
          <template slot-scope="{ option }">
            <div class="wxlistitem">
              <img :src="option.headimg" alt="headpic">
              <span>{{option.text}}</span>
            </div>
          </template>
          </md-selector>
        </md-field>
        <!-- <div class="submit" @click="submit">保存</div> -->
        <md-button :style="'background:'+getStorage('theme','')" type="primary" :loading="submitstatus" @click="submit"
          >保存</md-button
        >
      </div>
    </div>
     <div :class="personkey==='1'?'navsetting1':'navsetting'" :style="'background:'+getStorage('theme','')">
      <div class="backbtn" @click="backtopage" :style="'height:'+(personkey==='1'?'100%':'50%')">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">
        <span class="iconfont iconhome"></span>
      </div>
    </div>
    <!-- <div class="backbtn" @click="backtopage">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="personkey === '0'">home</div> -->
  </div>
</template>
<script>
import { getlist, addCustomer } from '@/api/abd/customer'
import {
  showfamilymember,
  addfamilymember,
  editfamilymember,
  selectfamwx,
  savewxToCustomer
} from '@/api/abd/family'
import loadMore from '@/mixins/loadmore'
import search from '@/components/search'
import dialog from '@/components/dialog'
import config from '@/config'
import { getSelectList } from '@/api/agent/agent'
import { Toast } from 'mand-mobile'
import { getStorage } from '@/lib/util'
const { redirect_uri } = config
export default {
  name: 'index',
  mixins: [loadMore],
  components: {
    search,
    dig: dialog
  },
  data () {
    return {
      // 指定微信
      wxnum: '',
      wxcardShow: false,
      wxcarddata: [],

      /*  */
      editflag: '添加成员',
      showedit: 'main',
      submitstatus: false,
      userlist: [],
      search: '',
      pageNo: 1,
      pageSize: 8,
      total: 0,
      totalPage: 0,
      loading: false,
      isDetail: false,
      createUser: '',
      addform: {
        name: '',
        relation: '00',
        sex: '1',
        birthdate: '',
        cardtype: '',
        cardno: ''
      },
      insurants: '0',
      sex: '1',
      isDatePickerShow: false,
      minDate: new Date('1940/1/1'),
      cardtype: '',
      cardSelectorShow: false,
      cardselectdata: [],
      cardnum: '',
      birth: '',
      name: '',
      user: {},
      id: '',
      edituserid: ''
      // cardtype: '',
      // cardno: '',
      // familyno: ''
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    this.personkey = this.$route.query.personkey
    this.getData()
  },
  methods: {
    addCustomer () {
      // `${'http://192.168.100.68:8080'}/confirm`
      addCustomer({ url: `${redirect_uri}/confirm` }).then(res => {
        this.createUser = res.data.data.url
        this.isDetail = !this.isDetail
      })
    },
    async getData (isInit = true) {
      let { search, pageNo, pageSize } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let res = await showfamilymember({
        famliyno: this.$route.query.familyno
      })
      // this.userlist = !isInit ? res.data.data : this.userlist.concat(res.data.data)
      this.userlist = res.data.data
      this.total = res.data.total
      this.totalPage = res.data.totalPage
      return res
    },
    Search () {
      // this.getData()
    },
    policyAnalysis (userid, cardno, cardtype) {
      this.$router.push({
        path: '/upload',
        query: { userid, cardno, cardtype }
      })
    },
    lookfampolicy (item) {
      this.$router.push({
        path: '/detail',
        query: {
          cardtype: item.cardtype,
          cardno: item.cardno,
          memid: item.id,
          familyno: item.familyno,
          name: item.name,
          empno: this.$route.query.empno || '',
          personkey: this.$route.query.personkey,
          empuserid: this.$route.query.empuserid,
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          fromwhere: this.$route.query.fromwhere
        }
      })
    },
    addfamember () {
      this.editflag = '添加成员'
      this.showedit = 'dialog'
      if (this.cardselectdata.length === 0) {
        getSelectList({ list: ['BBCARD'] }).then(res => {
          res.data.data.forEach(item => {
            switch (item.label) {
              case 'BBCARD':
                this.cardselectdata = item.value
                this.cardselectdata.forEach(item => {
                  item.value = item.code
                  item.text = item.name
                })
                break
              default:
                break
            }
          })
        })
      }
      selectfamwx({ familyno: this.$route.query.familyno, empno: this.user.empno, comid: this.user.comid }).then(res => {
        this.wxcarddata = res.data.data
        this.wxcarddata.forEach(item => {
          item.value = item.userid
          item.text = item.nickname
        })
      })
    },
    submit () {
      this.submitstatus = true
      if (this.addform.name === '') {
        Toast.failed('姓名不能为空！')
        this.submitstatus = false
      } else {
        if (this.editflag === '添加成员') {
          let jsonarr = []
          this.addform.empno =
            JSON.parse(sessionStorage.getItem('u_s')) === null
              ? JSON.parse(localStorage.getItem('u_s')).empno
              : JSON.parse(sessionStorage.getItem('u_s')).empno
          this.addform.familyno = this.$route.query.familyno
          jsonarr.push(this.addform)
          addfamilymember({ abtComEmpnomembers: JSON.stringify(jsonarr) })
            .then(res => {
              Toast.succeed('添加成功')
              this.addform = {
                name: '',
                relation: '00',
                sex: '1',
                birthdate: '',
                cardtype: '',
                cardno: ''
              }
              this.cardtype = ''
              this.showedit = 'main'
              this.getData()
              showfamilymember({ famliyno: this.$route.query.familyno }).then(
                res => {
                  this.membercount = res.data.data.length
                }
              )
            })
            .finally(() => {
              this.submitstatus = false
            })
        } else {
          if (this.wxnum !== '') {
            savewxToCustomer({ id: this.id, userid: this.edituserid }).then(res => { Toast.succeed(res.data.data) })
          }
          editfamilymember({ abtComAbdFamilyMembers: this.addform })
            .then(res => {
              Toast.succeed('更新成功')
              this.addform = {
                name: '',
                relation: '00',
                sex: '1',
                birthdate: '',
                cardtype: '',
                cardno: '',
                empno: '',
                familyno: ''
              }
              this.cardtype = ''
              this.showedit = 'main'
              this.getData()
            })
            .finally(() => {
              this.submitstatus = false
            })
        }
      }
    },
    /* 弹出层 */
    onDatePickerChange () {},
    onDatePickerConfirm (columnsValue) {
      this.addform.birthdate = this.$refs.datePicker2.getFormatDate(
        'yyyy-MM-dd'
      )
    },
    showcardSelector (selectag) {
      this.cardSelectorShow = true
    },
    oncardtypeChoose ({ value, text }) {
      this.cardtype = text
      this.addform.cardtype = value
      let cardnum = this.addform.cardno
      if (cardnum.length === 10) {
        this.addform.birthdate =
          cardnum.slice(6, 10) +
          '-' +
          cardnum.slice(10, 12) +
          '-' +
          cardnum.slice(12, 14)
      }
    },
    inputcardnum () {
      let cardnum = this.addform.cardno
      if (cardnum.length === 18) {
        if (this.cardtype === '身份证') {
          this.addform.birthdate =
            cardnum.slice(6, 10) +
            '-' +
            cardnum.slice(10, 12) +
            '-' +
            cardnum.slice(12, 14)
        }
      }
    },
    edit (listitem) {
      this.showedit = 'dialog'
      this.addfamember()
      this.editflag = '编辑成员'
      this.addform = listitem
      this.cardtype = this.addform.idcardvalue.label
      this.id = listitem.id
      if (listitem.nickname) {
        this.wxnum = listitem.nickname
      }
    },
    tomainpage () {
      this.wxnum = ''
      this.showedit = 'main'
      this.editflag = ''
      this.addform = {
        name: '',
        relation: '00',
        sex: '1',
        birthdate: '',
        cardtype: '',
        cardno: '',
        empno: '',
        familyno: ''
      }
      this.cardtype = ''
    },
    onwxcardChoose ({ text, value }) {
      this.edituserid = value
      this.wxnum = text
    },
    backtopage () {
      this.$router.push({ path: '/customercenter',
        query: {
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          fromwhere: this.$route.query.fromwhere
        } })
    },
    backtohomepage () { this.$router.push({ path: '/bdtg', query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true } }) }
  }
}
</script>
<style scoped lang="stylus">
.top {
  height: 200px;
  background-color: #2badda;
  /*background: url("../../../assets/abd/image/jiaodiantu-01.jpg") no-repeat;*/
  /*background-size: cover;*/
  position: relative;
}
.btn {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 30px;
}
.btn .iconxinjian1 {
  font-size: 36px;
  margin-top: 10px;
  margin-right: 18px;
}
.userPolicyInfo {
  padding: 30px;
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 20px;
}
.userPolicyInfo:last-child {
  margin: 0;
}
.person {
  width: 100%;
  margin-left 3vw
  display flex
  justify-content space-between
  align-items center
}
.p_name {
  padding-left: 29px;
  font-size: 31px;
}
.touxiang {
  width: 100px;
  height: 100px;
  vertical-align: middle;
}
.policyContainer {
  height: 1000px;
}
.list {
  height: 100%;
}
.policynum {
  color: #85888c;
}
.policynum .p_img {
  text-align: center;
}
.policynum .p_img img {
  width: 100px;
}
.familytitle{
  .famtitleleft{
    display flex
    flex-direction column
    p{
      color:rgba(255,255,255,1);
      font-family:PingFang SC;
      font-size 32px
      opacity 0.5
      letter-spacing 3px
    }
    .famtitletop{
      color:rgba(255,255,255,1);
      font-family:FZCuHeiSongS-B-GB;
      font-size 46px
      opacity 1
      margin-bottom 10px
    }
  }
  .famtitleright{
    img{
      width 18vw
      height 18vw
      position relative
      left 6vw
    }
  }
  display flex
  justify-content space-between
  align-items center
  height 16vh
  padding 0 6vw
  /*background: url('~@/assets/abd/image/s-101.png') no-repeat;*/
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bodylist{
            height 84vh
            overflow auto
            padding 0 6vw
        }
        .list{
                display flex
                height 12vh
                width 100%
                align-items center
                border-bottom 1px solid #F4F4F4
                .listleft{
                    display flex
                    align-items center
                    width 18%
                    img{
                        width 8vh
                        height 8vh
                        // border-radius 50%
                    }
                }
                .listright{
                    width 78%
                    display flex
                    height 100%
                    justify-content space-between
                    align-items center
                    .editbtn{
                      display flex
                      width 15vw
                      height 6vw
                      border-radius 30px
                      border 1px solid color-primary
                      justify-content center
                      align-items center
                      font-family:PingFang SC;
                      color:color-primary;
                    }
                    .name{
                        font-size 38px
                        /* margin-bottom 30px */
                        display flex
                        justify-content space-between
                        align-items center
                        width 100%
                        font-family:PingFang SC;
                        color:rgba(56,56,56,1);
                        margin-left 10px
                        span{
                            color #C1C3C5
                            font-size 32px
                        }
                    }
                    .detail{
                        span{
                            color #757575
                            font-size 32px
                        }
                        span:nth-child(1){
                           margin-right 50px
                        }
                    }
                }
            }
/* 弹出层 */
.addfamember{
    width 100vw
    height 150vh
    overflow-y auto
    position relative
    background-color #F7F6FB
    .addfamily-title{
        height 8vh
        display flex
        align-items center
        background-color white
        padding 0  4vw
        justify-content space-between
        span{
            font-size 36px
            font-family 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
            font-weight bold
            color #4B4B4B
        }
    }
    .addfamily-body{

    }
      /deep/ .md-button{
        background-color color-primary
        height 100px
        border-radius 8px
        width 90vw
        margin 0 5vw
        color white
        margin-top 100px
    }

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}
/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}
}
.wxlistitem{
  display flex
  justify-content space-between
  align-items center
  img{
    width 86px
    height 86px
    border-radius 50%
  }
}
// /deep/ .md-radio.is-checked .md-radio-icon {
//     color: color-primary
// }
// /deep/ .md-popup-title-bar .md-popup-confirm {
//     color:  color-primary;
// }
// /deep/ .md-picker-column-item .column-list .column-item.active {
//     color: color-primary;
//     font-weight: 500;
// }
.navsetting{
  width 10vw
  height 20vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.navsetting1{
  width 10vw
  height 10vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.backbtn{
  width 80%
  height 50%
  margin 0 auto
  border-bottom 0.8px solid rgba(255,255,255,0.5)
  display flex
  justify-content center
  align-items center
  span{
    width 100%
    display flex
    justify-content center
    align-items center
    color white
    font-size 50px
  }
}
.backtohome{
  width 80%
  height 50%
  margin 0 auto
  display flex
  justify-content center
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 50px
  }
}
</style>
